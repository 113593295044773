<template>
  <div>
    <apexchart
      type="radialBar"
      width="340"
      height="340"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import { englishToNepaliNumber } from "nepali-number";
import { defineAsyncComponent } from "vue";
const VueApexCharts = defineAsyncComponent(() => import("vue3-apexcharts"));

export default {
  name: "RadialBarChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    percentage: { type: Number, required: true, default: 0 },
    label: { type: String, required: true, default: "Test" },
    gradient: {
      type: Object,
      required: false,
      default: () => {
        return {
          from: "#F97794",
          to: "#623AA2",
        };
      },
    },
  },
  computed: {
    series() {
      return [this.percentage];
    },
    options() {
      return {
        chart: {
          width: 340,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "50%",
            },
            dataLabels: {
              show: true,
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: "32px",
                fontFamily: "Mukta",
                fontWeight: 700,
                color: "#000",
                offsetY: 16,
                formatter: function (val) {
                  return `${englishToNepaliNumber(val.toFixed(2))}%`;
                },
              },
            },
          },
        },
        labels: [this.label],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: "bottom",
          fontSize: "18px",
          offsetY: "-20",
          formatter: function (val) {
            return val;
          },
          markers: {
            height: 0,
            width: 0,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "diagonal2",
            shadeIntensity: 1,
            colorStops: [
              {
                offset: 0,
                color: this.gradient.from,
                opacity: 1,
              },
              {
                offset: 100,
                color: this.gradient.to,
                opacity: 1,
              },
            ],
          },
        },
        stroke: {
          lineCap: "round",
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                width: 240,
              },
            },
          },
        ],
      };
    },
  },
  data: function () {
    return {};
  },
};
</script>
